.btn {
  cursor: pointer;
  background: #418fde;
  border: 1px solid #418fde;
  color: #fff;
  padding: 16px 32px;
  font-size: 18px;
  text-decoration: none;
  font-weight: 400;
  text-transform: uppercase;
  align-self: flex-start;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #6aaeeb;
    border-color: #6aaeeb;
  }
  &.btn-sm {
    padding: 10px 26px;
    font-size: 14px;
  }
}

.btn[disabled] {
  cursor: not-allowed;
  background: #b5b5b5;
  border-color: #b5b5b5;
  &:hover {
    background: #b5b5b5;
    border-color: #b5b5b5;
  }
}

.form-group {
  margin-bottom: 30px;
  position: relative;
  label, input {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
  }
  label {
    margin-bottom: 12px;
  }
  input {
    width: 100%;
    max-width: 370px;
    height: 54px;
    background: #f8f9ff;
    padding: 0 20px;
    border: 2px solid #edeefb;
    color: #333;
    font-family: 'Open Sans', sans-serif;
    box-shadow: none;
    appearance: none;
    &:focus {
      outline: none;
    }
  }
  small {
    display: none;
    color: red;
  }
}

.form-checkbox {
  margin-bottom: 30px;
  small {
    display: none;
    color: red;
  }
  input[type="checkbox"]:checked,
  input[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  input[type="checkbox"]:checked + label,
  input[type="checkbox"]:not(:checked) + label {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    line-height: 20px;
    cursor: pointer;
  }

  input[type="checkbox"]:checked + label:before,
  input[type="checkbox"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 18px;
    height: 18px;
    border: 2px solid #edeefb;
    background-color: #f8f9ff;
  }

  input[type="checkbox"]:checked + label:before,
  input[type="checkbox"]:not(:checked) + label:before {
      border-radius: 2px;
  }

  input[type="checkbox"]:checked + label:after,
  input[type="checkbox"]:not(:checked) + label:after {
    content: "";
    position: absolute;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  input[type="checkbox"]:checked + label:after,
  input[type="checkbox"]:not(:checked) + label:after {
    left: 3px;
    top: 4px;
    width: 10px;
    height: 5px;
    border-radius: 1px;
    border-left: 4px solid #418fde;
    border-bottom: 4px solid #418fde;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
  }

  input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
}

.form.submitted {
  .form-group.has-error,
  .form-checkbox.has-error {
    small {
      display: inline;
    }
    input,
    input[type="checkbox"]:checked + label:before,
    input[type="checkbox"]:not(:checked) + label:before {
      border-color: red;
    }
  }
}

@media screen and (max-width: 812px) {
  .form {
    display: flex;
    flex-direction: column;
  }
  .btn {
    font-size: 14px;
    margin: 0 auto;
  }
  .form-group {
    label {
      text-align: center;
    }
    input {
      margin: 0 auto;
    }
  }
}
