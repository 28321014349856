.container {
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-2 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-3 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-right: 15px;
  padding-left: 15px;
}


@media screen and (max-width: 812px) {
  .locked {
    height: 50%;
    width: 100%;
    position: fixed;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-3{
    flex: 0 0 100%;
    max-width: 100%;
  }
}
