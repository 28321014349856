header {
  padding: 16px 0;
  position: absolute;
  width: 100%;
  z-index: 3;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &.fixed {
    z-index: 999;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  }
}

.main-box {
  background-image: url('../img/main-box.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  &:before {
    content: '';
    background: rgba(38, 38, 58, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .row {
    width: 100%;
  }

  .container {
    display: flex;
    align-items: center;
    min-height: 100vh;
    padding-top: 82px;
    padding-bottom: 10%;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    .col-2 {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      min-height: calc(100vh - 120px);
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h1 {
    color: #fff;
    margin: 50px 0 200px;
  }

  .btn {
    margin-top: 50px;
    align-self: center;
  }
}

.why-box {
  padding: 100px 0;
  background-color: #f8f9ff;

  .col-3 {
    margin-bottom: 30px;
  }
}

.item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 30px 20px;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 30px;

  &:hover img {
    transform: rotateY(360deg);
  }

  h3 {
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 100;
  }

  img {
    transition: all 0.5s ease-in;
  }
}

.how-box {
  padding: 100px 0;

  .col-2 {
    display: inline-flex;
    align-items: center;
    margin: 80px 0;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 100;
    padding: 20px;
    color: #2c3552;
  }
}

.info-box {
  background-color: #f8f9ff;
  padding: 80px 0;
  h3 {
    margin-bottom: 24px;
    color: #333;
    font-size: 20px;
  }
  .copy {
    text-align: center;
    border: 2px solid #418ede;
    padding: 20px;
    margin: -20px 0 40px;
  }
}

.feedback-box {
  padding: 80px 0;
  .btn {
    width: 100%;
    max-width: 370px;
  }
  .col-2:last-child {
    align-self: center;
    p {
      font-size: 20px;
      line-height: 30px;
      font-weight: 300;
    }
  }
}

footer {
  margin-top: 50px;
  background-color: #475869;
  padding: 50px 0;
  color: #fff;
  font-size: 14px;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  a {
    color: #fff;
  }
  img {
    width: 90px;
  }
}

.price-box {
  padding: 80px 0;
  p {
    margin-top: 32px;
    font-size: 18px;
    font-weight: bold;
    color: #465869;
    text-align: center;
  }
}

.price-list {
  display: flex;
  margin: 0 -16px;
  .price-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f8f9ff;
    border: 2px solid #418ede;
    margin: 16px;
    padding: 24px;
    h3 {
      color: #418ede;
      text-align: center;
      margin-bottom: 32px;
      line-height: 26px;
      font-size: 20px;
    }
    ul {
      padding: 0 0 0 24px;
      font-size: 16px;
      line-height: 20px;
      li {
        margin-bottom: 10px;
        list-style-type: square;
        color: #418ede;
        span {
          color: #333;
        }
      }
    }
    .count {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      padding: 24px;
      margin: 16px -24px -24px;
      background: #418ede;
      color: #fff;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 812px) {
  .main-box {
    .col-2 {
      &:first-child {
        position: relative;
        z-index: 4;
      }
      &:last-child {
        position: absolute;
        top: 100px;
        opacity: 0.9;
      }
    }
    h1 {
      margin: 0 0 20px;
      font-size: 28px;
      line-height: normal;
    }
    .btn {
      margin-top: 20px;
    }
  }
  .why-box {
    margin-top: 0;
    padding: 40px 0;
    .col-3 {
      justify-content: center;
      display: flex;
      margin: 0;
    }
  }
  .item {
    max-width: 400px;
    width: 100%;
    margin-bottom: 0;
  }
  .how-box {
    padding: 40px 0;
    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
      line-height: 24px;
    }
    .row {
      .col-2 {
        margin: 25px 0;
        &:nth-child(1) {
          order: 2;
          margin-bottom: 50px;
        }
        &:nth-child(2) {
          order: 1;
        }
        &:nth-child(3) {
          order: 3;
        }
        &:nth-child(4) {
          order: 4;
          margin-bottom: 50px;
        }
        &:nth-child(5) {
          order: 6;
        }
        &:nth-child(6) {
          order: 5;
        }
        img {
          max-width: 100%;
          margin: 0 auto;
        }
      }
    }
  }
  .info-box {
    padding: 40px 0;
  }
  .feedback-box {
    padding: 40px 0;
    h2 {
      margin-bottom: 40px;
    }
    .row {
      .col-2 {
        &:first-child {
          margin-top: 80px;
          order: 2;
        }
        &:last-child {
          text-align: center;
          p {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .price-box {
    padding: 40px 0;
  }
  .price-list {
    margin: 0;
    flex-direction: column;
    .price-item {
      margin: 16px 0;
    }
  }
  footer {
    font-size: 12px;
    line-height: 16px;
    margin-top: 20px;
  }
}
