.ReactModal__Overlay {
    z-index: 1000;
    max-height: 100vh;
    overflow: auto;
    background: rgba(58, 58, 58, 0.75)!important;

    .ReactModal__Content {
        position: relative;

        h2 {
            margin-bottom: 30px;
        }

        .modal-close {
            position: absolute;
            top: 20px;
            right: 20px;
            background: transparent;
            border: none;
            font-size: 25px;
            color: #ccc;
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }
    }
}

.ReactModal__Body--open {
    overflow: hidden;
}
