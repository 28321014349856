h1 {
  font-size: 46px;
  font-weight: 800;
  letter-spacing: -1px;
}

h2 {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  line-height: 48px;
  color: #418fde;
  margin: 0 0 60px;
}

h3 {
  font-size: 18px;
  font-weight: 500;
  color: #2c3552;
}

a {
  color: #418FDE;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 812px) {
  h1 {
    font-size: 30px;
    margin: 100px 0 30px;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 30px;
  }

  h3 {
    font-size: 18px;
  }
}
