.nav {
  margin: 0;
  padding: 0;
  display: flex;

  li {
    margin: 0;
    display: inline-block;

    a {
      cursor: pointer;
      color: #fff;
      text-decoration: none;
      margin-left: 24px;
      font-size: 16px;
      font-weight: 600;
      &.active {
        color: #418fde;
      }
    }
  }
}

.logo {
  cursor: pointer;
  display: inline-flex;
  background: url('../img/logo.svg') no-repeat;
  background-size: auto 50px;
  width: 155px;
  height: 50px;
}

.menu,.hamburger-icon,.mobile{
  display: none;
}

.fixed {
  .nav a {
    color: #3c3c3c;
    &.btn {
      color: #fff;
    }
  }
}

@media screen and (max-width: 812px) {
  .logo {
    background-size: auto 40px;
    height: 40px;
  }
  header {
    padding: 10px 0;
    transform: none!important;
    z-index: 10;
    .container {
      position: static;
      align-items: normal;
      img {
        height: 30px;
        margin-left: 10px;
        position: relative;
        z-index: 2;
      }
    }
    .nav {
      display: none;
      li a {
        &.btn {
          font-size: 14px;
          color: #fff!important;
        }
      }
    }
    &.fixed {
      padding-top: 0;
      background: #fff!important;
      .container {
        position: static;
        padding: 10px 20px 0;
        .hamburger-icon {
          top: 4px;
          .line {
            background: #418fde;
          }
        }
      }
    }
  }
  .menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    transition: z-index .01s ease-in-out .2s;
    .background {
      position: fixed;
      display: flex;
      flex-flow: column;
      align-items: stretch;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .portion {
        transition: transform .2s ease-in-out;
        flex: 1 1 100%;
        background-color: #f8f9ff;
        &:nth-child(odd) {
          transform: translateX(-100%);
        }
        &:nth-child(2n) {
          transform: translateX(100%);
        }
        &:not(:first-child) {
          margin-top: -1px;
        }
      }
    }
    .menu-content {
      opacity: 0;
      z-index: 10;
      position: relative;
      height: 100%;
      padding: 100px 30px 30px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .logo {
        position: absolute;
        top: 10px;
        left: 20px;
        height: 40px;
        background-size: auto 40px;
      }
      .nav {
        display: block;
        li {
          display: block;
          font-size: 18px;
          font-weight: 700;
          margin: 0 0 35px;
          min-width: auto;
          min-height: auto;
          a {
            color: #418FDE;
            text-shadow: none;
            position: static;
            margin: 0;
          }
        }
      }
    }
    &.menu-open {
      display: block;
      transition: z-index .01s ease-in-out .3s;
      z-index: 10;
      opacity: 1;
      .background {
        .portion {
          transform: translateX(0);
          transition: transform .2s ease-in-out .3s;
        }
      }
      .menu-content {
        opacity: 1;
        transition: opacity .3s ease-in-out .4s;
      }
    }
  }
  .hamburger-icon {
    background: transparent;
    width: 50px;
    height: 50px;
    position: absolute;
    display: block;
    top: 5px;
    right: 20px;
    border: 0;
    cursor: pointer;
    z-index: 100;
    .line {
      display: block;
      background: #fff;
      width: 20px;
      height: 3px;
      position: absolute;
      left: 15px;
      border-radius: 1.5px;
      transition: all .4s;
      &.line-1 {
        top: 18px;
      }
      &.line-2 {
        top: 24px;
      }
      &.line-3 {
        bottom: 17px;
      }
    }
    &.active {
      .line-1 {
        top: 14px;
        transform: translateY(9px) translateX(0) rotate(45deg);
      }
      .line-2 {
        opacity: 0;
      }
      .line-3 {
        bottom: 13px;
        transform: translateY(-11px) translateX(0) rotate(-45deg);
      }
    }
    &:hover,&:focus {
      outline: none;
    }
  }
}

