.rc-collapse {
  padding: 0;
  margin-bottom: 70px;
}
.rc-collapse-anim-active {
  transition: height 0.2s ease-out;
}

.rc-collapse > {
  .rc-collapse-item {
    outline: none;
    margin-bottom: 15px;

    &:first-child {
      border-top: none;
    }

    > .rc-collapse-header {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      outline: none;
      align-items: center;
      line-height: 22px;
      padding: 5px 16px;
      font-size: 16px;
      color: #000;
      border: 2px solid #d9dbfc;
      cursor: pointer;

      .arrow {
        order: 2;
        display: inline-block;
        width: 40px;
        height: 40px;
        border: 0;
        font-size: 1.5em;
        position: relative;
        &:before,&:after {
          content: '';
          display: inline-block;
          position: absolute;
          transition: .3s;
          background: #418ede;
          border-radius: 2px;
        }
        &:before {
          top: 25%;
          bottom: 25%;
          width: 10%;
          left: 45%;
        }
        &::after {
          left: 25%;
          right: 25%;
          height: 10%;
          top: 45%;
        }
      }

      .rc-collapse-extra {
        margin: 0 16px 0 auto;
      }
    }
  }

  .rc-collapse-item-disabled > .rc-collapse-header {
    cursor: not-allowed;
    color: #999;
    background-color: #f3f3f3;
  }
}

.rc-collapse-content {
  overflow: hidden;
  color: #333;
  padding: 0 16px;
  font-size: 18px;
  font-weight: 100;
  line-height: 24px;
  border: 2px solid #d9dbfc;
  border-top-color: #f8f9ff;
  position: relative;
  top: -2px;

  > .rc-collapse-content-box {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.rc-collapse-content-inactive {
  display: none;
}

.rc-collapse-item:last-child > .rc-collapse-content {
  border-radius: 0 0 3px 3px;
}

.rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {
  &:before,&:after {
    transform: rotate(90deg);
  }
  &:after {
    left: 50%;
    right: 50%;
  }
}

@media screen and (max-width: 812px) {
  .rc-collapse {
    & > .rc-collapse-item {
      & > .rc-collapse-header {
        padding: 5px 10px;
        font-size: 15px;
        .arrow {
          min-width: 30px;
          width: 30px;
          height: 30px;
        }
      }
    }

    .rc-collapse-content {
      padding: 0;
      font-size: 15px;
      line-height: 22px;
      & > .rc-collapse-content-box {
        padding: 0 10px;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }
}
